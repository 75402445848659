var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "banner-manage base-container"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        _vm.toggleEditDialog = true;
      }
    }
  }, [_vm._v("发布广告")])], 1), _c('div', {
    staticClass: "filter"
  }, [_c('el-select', {
    staticClass: "select",
    attrs: {
      "placeholder": "状态",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "status", $$v);
      },
      expression: "queryData.status"
    }
  }, _vm._l(_vm.status, function (item, key) {
    return _c('el-option', {
      key: key,
      attrs: {
        "label": item,
        "value": key
      }
    });
  }), 1), _c('el-cascader', {
    staticClass: "select",
    attrs: {
      "placeholder": "广告位",
      "clearable": "",
      "options": _vm.advertTypeTree,
      "props": {
        label: 'name',
        value: 'id',
        emitPath: false
      }
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.advertTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "advertTypeId", $$v);
      },
      expression: "queryData.advertTypeId"
    }
  }), !_vm.distributionFlag ? _c('el-select', {
    staticClass: "select",
    attrs: {
      "placeholder": "终端",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.device,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "device", $$v);
      },
      expression: "queryData.device"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "小程序",
      "value": "2"
    }
  }), _c('el-option', {
    attrs: {
      "label": "APP",
      "value": "1"
    }
  })], 1) : _vm._e()], 1), _c('el-table', {
    staticClass: "body",
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      "border": "",
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "广告图片",
      "width": "460px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "base-info"
        }, [_c('el-image', {
          staticClass: "cover",
          attrs: {
            "fit": "cover",
            "src": scope.row.image
          }
        }), _c('div', {
          staticClass: "text"
        }, [_c('p', {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(scope.row.title) + " ")]), _c('div', {
          staticClass: "sub"
        }, [_c('p', [_vm._v("排序号：" + _vm._s(scope.row.sort))]), _c('p', [_vm._v("广告位：" + _vm._s(scope.row.advertTypeName))])])])], 1)];
      }
    }])
  }), !_vm.distributionFlag ? _c('el-table-column', {
    attrs: {
      "label": "终端",
      "prop": "device"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.device == 1 ? "房猫找房APP" : "小程序") + " ")];
      }
    }], null, false, 1129881550)
  }) : _vm._e(), _c('el-table-column', {
    attrs: {
      "label": "链接",
      "prop": "url"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', {
          class: {
            red: scope.row.status === 0
          }
        }, [_vm._v(" " + _vm._s(_vm.status[scope.row.status]) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "创建"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [_vm._v(" " + _vm._s(scope.row.inUserName) + " ")]), _c('br'), _c('p', [_vm._v(" " + _vm._s(scope.row.inDate) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "180px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.status != 1 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.changeAdvertStatus(scope.row.id, 1);
            }
          }
        }, [_vm._v("发布")]) : _vm._e(), scope.row.status == 1 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.changeAdvertStatus(scope.row.id, 0);
            }
          }
        }, [_vm._v("下架")]) : _vm._e(), _c('el-button', {
          staticClass: "mlr10",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showEdit(scope.row);
            }
          }
        }, [_vm._v("编辑")]), scope.row.status != 1 ? _c('el-popconfirm', {
          attrs: {
            "title": "确定删除该广告？"
          },
          on: {
            "onConfirm": function onConfirm($event) {
              return _vm.deleteAdvert(scope.row.id);
            }
          }
        }, [_c('el-button', {
          attrs: {
            "slot": "reference",
            "type": "text"
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1) : _vm._e()];
      }
    }])
  })], 1), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getAdvertManageList
    }
  }), _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "close-on-click-modal": false,
      "title": _vm.formData.id ? '编辑广告' : '发布广告',
      "visible": _vm.toggleEditDialog,
      "width": "490px",
      "before-close": _vm.resetForm
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleEditDialog = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    staticClass: "f-form",
    attrs: {
      "model": _vm.formData,
      "label-width": "150px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "advertTypeId",
      "label": "广告位：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-cascader', {
    staticClass: "W100",
    attrs: {
      "options": _vm.advertTypeTree,
      "props": {
        label: 'name',
        value: 'id',
        emitPath: false
      }
    },
    model: {
      value: _vm.formData.advertTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "advertTypeId", $$v);
      },
      expression: "formData.advertTypeId"
    }
  })], 1), !_vm.distributionFlag ? _c('el-form-item', {
    attrs: {
      "prop": "device",
      "label": "上架终端",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.device,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "device", $$v);
      },
      expression: "formData.device"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": "2"
    }
  }, [_vm._v("小程序")]), _c('el-radio-button', {
    attrs: {
      "label": "1"
    }
  }, [_vm._v("房猫找房APP")])], 1)], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "prop": "title",
      "label": "标题：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "url",
      "label": "地址/路径：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.url,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "url", $$v);
      },
      expression: "formData.url"
    }
  }), _c('p', {
    staticStyle: {
      "padding-top": "10px"
    }
  }, [_c('a', {
    staticClass: "blue",
    attrs: {
      "href": "https://fangyibao-res.oss-cn-hangzhou.aliyuncs.com/doc/routeMaps.txt",
      "target": "__blank"
    }
  }, [_vm._v("《“地址/路径”填写指导》")])])], 1), _c('el-form-item', {
    attrs: {
      "prop": "image",
      "rules": [_vm.$formRules.required()],
      "label": "广告图片："
    }
  }, [_c('p', {
    staticClass: "tips"
  }, [_vm._v("支持jpg、png、gif格式，建议大小1M以内")]), _c('f-upload', {
    staticClass: "banner-uploader",
    attrs: {
      "type": 2,
      "limitSize": 1,
      "default-file-list": [{
        url: _vm.formData.image
      }]
    },
    on: {
      "on-success": _vm.bannerImageUploadSuccess
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "sort",
      "label": "排序：",
      "rules": [_vm.$formRules.required(), _vm.$formRules.int2]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入数字，越小排序越靠前",
      "maxlength": "100"
    },
    model: {
      value: _vm.formData.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sort", _vm._n($$v));
      },
      expression: "formData.sort"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.resetForm
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("广告管理")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v(" 为了保持页面的美观度，请尽量按照广告要求尺寸、比例发布相应广告 ")])]);

}]

export { render, staticRenderFns }